import React from 'react'
import ReactDOM from 'react-dom/client'
import './app/styles/index.scss'
import { App } from './app/App'
import { BrowserRouter } from 'react-router-dom'
import { ModalProvider } from 'features/Modal/ModalContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ModalProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ModalProvider>
  </React.StrictMode>
)
