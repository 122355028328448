import { FC, Suspense, useLayoutEffect } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Header } from 'widgets/Header'
import { Footer } from 'widgets/Footer'
import { ComponentLoader } from 'widgets/ComponentLoader/ComponentLoader'
import { AppRouter } from './providers/AppRouter/AppRouter'
import GoogleAnalytics from './GoogleAnalytics/GoogleAnalytics';


export const App: FC = () => {
  useLayoutEffect(() => {
    document.body.setAttribute('class', 'dark-theme')
  }, [])

  return (
    <>
      <GoogleAnalytics />
      <div className={'app'}>
        <Header containerClassName={'container'} />

        <main style={{ minHeight: '100vh' }}>
          <Suspense fallback={''}>
            <AppRouter />
          </Suspense>
        </main>

        <LazyLoadComponent
          placeholder={<div style={{ height: '100vh' }} />}
          threshold={-10}
        >
          <Suspense fallback={<ComponentLoader />}>
            <Footer containerClassName={'container'} />
          </Suspense>
        </LazyLoadComponent>
      </div>
    </>

  )
}
