// ModalContext.tsx

import React, { createContext, useContext, useState, FC, ReactNode } from 'react'
import { QRCodeCanvas } from 'qrcode.react'

interface ModalContextProps {
    isOpen: boolean
    openModal: () => void
    closeModal: () => void
}

export const ModalContext = createContext<ModalContextProps>({
    isOpen: false,
    openModal: () => { },
    closeModal: () => { },
})

export const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    return (
        <ModalContext.Provider value={{ isOpen, openModal, closeModal }}>
            {children}

            {isOpen && (
                // Overlay that closes the modal when clicked
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(0,0,0,0.5)',
                        zIndex: 9999,
                    }}
                    onClick={closeModal} // Close modal if clicking outside container
                >
                    {/* Modal container that stops clicks from closing the modal */}
                    <div
                        style={{
                            position: 'relative',
                            background: '#fff',
                            padding: '1rem',
                            borderRadius: '8px',
                            width: '500px',
                            maxWidth: '90%',
                            textAlign: 'center',
                        }}
                        onClick={(e) => e.stopPropagation()} // Prevent overlay click from closing
                    >
                        {/* Cross icon in the top-right corner */}
                        <div
                            style={{
                                position: 'absolute',
                                top: '0.5rem',
                                right: '0.5rem',
                                cursor: 'pointer',
                                fontSize: '1.5rem',
                                lineHeight: '1rem',
                            }}
                            onClick={closeModal}
                        >
                            &times;
                        </div>

                        <h2>Download Our App</h2>
                        <p>Scan the QR code below to get our iOS or Android app.</p>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                margin: '1rem 0',
                            }}
                        >
                            {/* iOS QR Code */}
                            <div>
                                <QRCodeCanvas
                                    value="https://apps.apple.com/in/app/safle-web3-wallet/id6444435472"
                                    size={128}
                                />
                                <p>iOS</p>
                            </div>

                            {/* Android QR Code */}
                            <div>
                                <QRCodeCanvas
                                    value="https://play.google.com/store/apps/details?id=com.safle"
                                    size={128}
                                />
                                <p>Android</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ModalContext.Provider>
    )
}

export const useModal = () => useContext(ModalContext)